import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import Link from 'Shared/Link';
import {
  minMediumMediaQuery,
  minLargeMediaQuery,
  minTinyMediaQuery,
  minSmallMediaQuery,
  minHugeMediaQuery,
} from 'Shared/Style';
import PromotionCardViewModel from './PromotionCardViewModel.type';

type PropType = StyledProps & PromotionCardViewModel & { lessCardsInRow: boolean };

export default styled(function PromotionCard(props: PropType) {
  const { link, promotionOverviewImage, name, backgroundColor, textBackgroundColor, textColor, lessCardsInRow } = props;
  const defaultTextColor = '#fff';
  const defaultTextBackgroundColor = '#000';

  const lessCardsInRowStyle: Style = {
    height: '250px',
    [minSmallMediaQuery]: {
      width: `calc(100% / ${TINY_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
    [minMediumMediaQuery]: {
      width: `calc(100% / ${SMALL_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
    [minHugeMediaQuery]: {
      width: `calc(100% / ${MEDIUM_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
  };

  const moreCardsInRowStyle: Style = {
    height: '185px',
    [minTinyMediaQuery]: {
      width: `calc(100% / ${TINY_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
    [minSmallMediaQuery]: {
      width: `calc(100% / ${SMALL_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
    [minMediumMediaQuery]: {
      width: `calc(100% / ${MEDIUM_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
    [minLargeMediaQuery]: {
      width: `calc(100% / ${LARGE_CARDS_PER_ROW} - ${GUTTER}px)`,
    },
  };

  const imageContainerLessCardsStyle: Style = {
    maxHeight: 'unset',
    [minSmallMediaQuery]: {},
  };

  const imageContainerMoreCardsStyle: Style = {
    // [minTinyMediaQuery]: {
    //   height: '135px',
    // },
  };

  return (
    <PromotionCardWrapper css={lessCardsInRow ? lessCardsInRowStyle : moreCardsInRowStyle}>
      <PromotionPageLink
        to={link}
        css={
          backgroundColor
            ? {
                backgroundColor: `${backgroundColor}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }
            : {
                backgroundColor: 'white',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                overflow: 'hidden',
              }
        }
      >
        {name && (
          <PromotionName
            css={
              backgroundColor && !promotionOverviewImage
                ? {
                    position: 'unset',
                    backgroundColor: 'unset',
                    textAlign: 'center',
                    fontSize: '25px',
                    color: `${textColor ? textColor : defaultTextColor}`,
                  }
                : { backgroundColor: `${textBackgroundColor ? textBackgroundColor : defaultTextBackgroundColor}` }
            }
          >
            {name}
          </PromotionName>
        )}
        <Wrapper>
          {promotionOverviewImage && (
            <ImageContainer css={lessCardsInRow ? imageContainerLessCardsStyle : imageContainerMoreCardsStyle}>
              <ImageStyled src={promotionOverviewImage.url} />
            </ImageContainer>
          )}
        </Wrapper>
      </PromotionPageLink>
    </PromotionCardWrapper>
  );
});

const TINY_CARDS_PER_ROW = 2;
const SMALL_CARDS_PER_ROW = 3;
const MEDIUM_CARDS_PER_ROW = 4;
const LARGE_CARDS_PER_ROW = 5;
const GUTTER = 10;

const PromotionCardWrapper = styled.div({
  position: 'relative',
  textDecoration: 'inherit',
  color: 'inherit',
  minHeight: '150px',
  display: 'flex',
  width: '100%',
  margin: { xy: '5px' },
  boxShadow: '3px 3px 7px rgba(0,0,0,0.3)',
  padding: { xy: '10px' },
});

const Wrapper = styled.div({
  position: 'relative',
});

const PromotionPageLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textDecoration: 'inherit',
  overflow: 'hidden',
  color: 'inherit',
  height: '100%',
  width: '100%',
});

const PromotionName = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
  textTransform: 'uppercase',
  fontWeight: 'bold',
  color: 'white',
  padding: { x: '5px', y: 0 },
  borderRadius: '2px',
});

const ImageContainer = styled.div({
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  height: '100%',
  overflow: 'hidden',
});

const ImageStyled = styled.img({
  width: '100%',
  height: '100%',
  objectFit: 'fill',
});
