/**
 * @ComponentFor PromotionOverviewPageViewModel
 */
import React from 'react';
import PromotionCard from './PromotionCard';
import PromotionOverviewPageViewModelType from './PromotionOverviewPageViewModel.type';
import { Main, Appearance } from 'Shared/PageLayout';
import { styled } from '@glitz/react';
import { epiPropertyValue } from '@avensia/scope-episerver';
type PropType = PromotionOverviewPageViewModelType;

const PromotionOverviewPage = (props: PropType) => {
  const PromosContainer = styled.div({
    display: 'flex',
    flexFlow: 'row wrap',
  });

  const lessCardsInRow = epiPropertyValue(props.page.lessCardsInRow);

  return (
    <Main appearance={Appearance.Narrow}>
      <PromosContainer>
        {props.promotions &&
          props.promotions.map((promotion, i) => {
            return <PromotionCard lessCardsInRow={lessCardsInRow} {...promotion} key={promotion.name + i} />;
          })}
      </PromosContainer>
    </Main>
  );
};

export default React.memo(PromotionOverviewPage);
